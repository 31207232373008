export const initState = {
    infoEnv: null
}

const changelog = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_INFO_DEV_SUCCESS':
            return { ...state, infoEnv: action.infoEnv }
        default:
            return state
    }
}
export const getInfoEnv = state => {
    return state.infoEnv.infoEnv
}
export default changelog
