import React from 'react'

import iconoTemporalAcabado from '../../../assets/img/acabado.png'
import ProductDisplayName from '../../global/ProductDisplayName'

//Displays
import DisplayProductInfo from './displays/DisplayProductInfo'
import DisplayBrand from './displays/DisplayBrand'
import DisplayThickness from './displays/DisplayThickness'
import DisplayGroupDtails from './displays/DisplayGroupDtails'
import DisplayDetail from './displays/DisplayDetail'

export default class DetailsIkea extends React.Component {
    render() {
        return (
            <DisplayProductInfo>
                <DisplayBrand brand={this.props.product.brand} />
                <div className="product-color-name">
                    <ProductDisplayName product={this.props.product} />
                </div>
                <DisplayGroupDtails>
                    <DisplayThickness thickness={this.props.product.thickness} />
                    <DisplayDetail icon={iconoTemporalAcabado} value={this.props.product.finish} />
                </DisplayGroupDtails>
                <DisplayGroupDtails>
                    <DisplayDetail
                        class="subtypeName"
                        value={this.props.product.subtypeName && this.props.intl.formatMessage({ id: this.props.product.subtypeName.toLowerCase().replace(' ', '_') })}
                    />
                </DisplayGroupDtails>
                <DisplayGroupDtails>
                    <DisplayDetail value={this.props.product.bigAccountsErpCode ? '# ' + this.props.product.bigAccountsErpCode : null} />
                </DisplayGroupDtails>
            </DisplayProductInfo>
        )
    }
}
