import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getProfileConfiguration } from '../../store/profile-configuration/reducers'

const mapStateToProps = state => {
    return { userPreferences: getProfileConfiguration(state) }
}
const WidthLotMeasure = ({ userPreferences, intl, original }) => {
    const localeWithft2 = ['en-US', 'fr-CA', 'en-CA']
    const userLengthPrefer = userPreferences.lengthUnit === 'M2' ? original.width_m : original.width_ft
    const lengthDefault = localeWithft2.includes(intl.locale) ? original.width_ft : original.width_m
    const meters2 = userPreferences.lengthUnit ? parseFloat(userLengthPrefer).toFixed(2) : parseFloat(lengthDefault).toFixed(2)
    return meters2
}

export default injectIntl(connect(mapStateToProps, null)(WidthLotMeasure))
