import React from 'react'

class CosentinoSingleSignOn extends React.Component {
    constructor(props) {
        super(props)

        window.addEventListener(
            'message',
            e => {
                if (e.origin === 'https://sso.cosentino.com') {
                    if (e.data && e.data.success) {
                        props.onSuccess(e.data)
                    } else {
                        props.onError(e.data)
                    }
                }
            },
            false
        )
    }

    start = () => {
        const params = {}

        const query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&')

        window.open('https://sso.cosentino.com/start?' + query, 'Salesforce')
    }

    render() {
        return <div onClick={this.start}>{this.props.children}</div>
    }
}

export default CosentinoSingleSignOn
