import React from 'react'
import Translation from '../global/Translation'

import Modal from './Modal'
import Button from './Button'
import outlet1 from '../../assets/img/outlet1.png'
import outlet2 from '../../assets/img/outlet2.png'

const OutletModal = props => {
    return (
        <Modal
            footer={
                <Button inverted size="medium" onClick={props.onClose} datacy="outlet_modal__close">
                    <Translation id="accept" defaultMessage="Accept" />
                </Button>
            }
            size="large"
            title={<Translation id="outlet_hq" defaultMessage="You are in Outlet HQ!" />}
            onClose={props.onClose}
        >
            <div className="container" data-cy="outlet_modal__container">
                <div className="col-12">
                    <p>
                        <Translation
                            id="please_dont_forget_indicate_outlet"
                            defaultMessage="Please do not forget to indicate, in case of formal order of slabs,  OX / OB / OV  as follows below. In case an order is not make correctly, this can lead to problems or delays."
                        />
                    </p>
                    <p>
                        <Translation id="more_info_at" defaultMessage="More info at" />
                        {props.locale === 'es' ? (
                            <a href="https://news.cosentino.com/news/cosentino-outlet_es/" target="_blank" rel="noopener noreferrer">
                                {' '}
                                https://news.cosentino.com/news/cosentino-outlet_es/
                            </a>
                        ) : (
                            <a href="https://news.cosentino.com/news/cosentino-outlet_en/" target="_blank" rel="noopener noreferrer">
                                {' '}
                                https://news.cosentino.com/news/cosentino-outlet_en/
                            </a>
                        )}
                    </p>
                    <p>
                        <Translation id="contact_product_department_if_needed" defaultMessage="Please do not hesitate to contact to Product Department if needed" />
                    </p>
                    <p>
                        <Translation id="1_customer_sales" defaultMessage="1.-CUSTOMER SALES" />
                        <br />
                        <a href={outlet1} target="_blank" rel="noopener noreferrer">
                            <img alt="" src={outlet1} className="img-fluid" style={{ width: '300px' }} />
                        </a>
                    </p>
                    <p>
                        <Translation id="2_creation_of_transfer_order_to_hq" defaultMessage="2.-CREATION OF TRANSFER ORDER TO HQ" />
                        <br />
                        <a href={outlet2} target="_blank" rel="noopener noreferrer">
                            <img alt="" src={outlet2} className="img-fluid" style={{ width: '300px' }} />
                        </a>
                    </p>
                </div>
            </div>
        </Modal>
    )
}

export default OutletModal
