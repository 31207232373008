import React from 'react'

import LocalizedLink from '../../../global/LocalizedLink'

const KpiTabButton = ({ link, icon, number, active }) => {
    const kpiClass = active ? 'kpi active' : 'kpi'
    return (
        <LocalizedLink routeId={link}>
            <div className={kpiClass}>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="icon">
                            <img alt="" src={icon} />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="number">{number}</div>
                    </div>
                </div>
            </div>
        </LocalizedLink>
    )
}

export default KpiTabButton
