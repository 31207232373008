import React from 'react'

class IkeaOrderStatus extends React.Component {
    render() {
        const original = this.props.original
        return (
            <div>
                <div className="tracking-progress-bar">
                    {parseInt(original.status, 10) < 7 && (
                        <div>
                            <div
                                className={`tracking-progress-bar__item tracking-progress-bar__item--first
                                                            ${
                                                                parseInt(original.status, 10) >= 1 && parseInt(original.status, 10) < 7
                                                                    ? 'tracking-progress-bar__item--active'
                                                                    : null
                                                            }`}
                            >
                                <i className="far fa-paper-plane pp-align-center" />
                            </div>

                            <span
                                className={`tracking-progress-bar__item__bar ${
                                    parseInt(original.status, 10) >= 2 && parseInt(original.status, 10) < 7 ? 'tracking-progress-bar__item__bar--active' : null
                                }`}
                            />
                            <div
                                className={`tracking-progress-bar__item tracking-progress-bar__item--first
                                                            ${
                                                                parseInt(original.status, 10) >= 3 && parseInt(original.status, 10) < 7
                                                                    ? 'tracking-progress-bar__item--active'
                                                                    : null
                                                            }`}
                            >
                                <i className="far fa-thumbs-up" />
                            </div>

                            <span
                                className={`tracking-progress-bar__item__bar ${
                                    parseInt(original.status, 10) >= 4 && parseInt(original.status, 10) < 7 ? 'tracking-progress-bar__item__bar--active' : null
                                }`}
                            />
                            <div
                                className={`tracking-progress-bar__item tracking-progress-bar__item--first
                                                            ${
                                                                parseInt(original.status, 10) >= 5 && parseInt(original.status, 10) < 7
                                                                    ? 'tracking-progress-bar__item--active'
                                                                    : null
                                                            }`}
                            >
                                <i className="fas fa-truck pp-align-center" />
                            </div>
                            <span
                                className={`tracking-progress-bar__item__bar ${
                                    parseInt(original.status, 10) >= 6 && parseInt(original.status, 10) < 7 ? 'tracking-progress-bar__item__bar--active' : null
                                }`}
                            />
                            <div
                                className={`tracking-progress-bar__item tracking-progress-bar__item--first
                                                            ${
                                                                parseInt(original.status, 10) >= 6 && parseInt(original.status, 10) < 7
                                                                    ? 'tracking-progress-bar__item--active'
                                                                    : null
                                                            }`}
                            >
                                <i className="fas fa-file" />
                            </div>
                        </div>
                    )}
                    {parseInt(original.status, 10) >= 7 && parseInt(original.status, 10) < 9 && (
                        <div className="error">
                            <div
                                className={`tracking-progress-bar__item tracking-progress-bar__item--first
                                                            ${parseInt(original.status, 10) === 7 ? 'tracking-progress-bar__item--active' : null}`}
                            >
                                <i className="far fa-paper-plane pp-align-center" />
                            </div>

                            <span className={`tracking-progress-bar__item__bar ${parseInt(original.status, 10) === 7 ? 'tracking-progress-bar__item__bar--active' : null}`} />
                            <div
                                className={`tracking-progress-bar__item tracking-progress-bar__item--first 
                                                            ${parseInt(original.status, 10) === 7 ? 'tracking-progress-bar__item--active' : null}`}
                            >
                                <i className="far fa-thumbs-down .td-align-center" />
                            </div>
                        </div>
                    )}
                    {parseInt(original.status, 10) >= 9 && (
                        <div className="update">
                            <div
                                className={`tracking-progress-bar__item tracking-progress-bar__item--first
                                                            ${parseInt(original.status, 10) === 9 ? 'tracking-progress-bar__item--active' : null}`}
                            >
                                <i className="far fa-sync icon-align-vertical td-align-center" />
                            </div>
                        </div>
                    )}
                </div>
                <span>{original.statusText}</span>
            </div>
        )
    }
}

export default IkeaOrderStatus
