import React from 'react'

import LotImageViewerContainer from '../components/lot-image-viewer/LotImageViewerContainer'
import GlobalModals from '../components/layout/GlobalModals'

export default props => (
    <div>
        <LotImageViewerContainer {...props} />
        <GlobalModals />
    </div>
)
