import React from 'react'

import ErrorContainer from '../components/alerts/ErrorContainer'
import MktAdminColorsContainer from '../components/mkt-admin/MktAdminColorsContainer'

const mapStateToProps = state => {
    return {
        isAdminMkt: true
    }
}

const MktAdminColorsScreen = mapStateToProps().isAdminMkt ? () => <MktAdminColorsContainer /> : () => <ErrorContainer />

export default MktAdminColorsScreen
