import * as api from '../api'

export const fetchReservations = unassigned => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({
        type: 'FETCH_RESERVATIONS_REQUEST',
        unassigned,
        token
    })

    return api
        .fetchReservations(token, unassigned)

        .then(
            response => {
                dispatch({ type: 'FETCH_RESERVATIONS_SUCCESS', reservations: response.data })
                if (unassigned) dispatch({ type: 'FETCH_RESERVATIONS_UNASSIGNED_COUNT', reservations: response.data })
            },
            error => {
                dispatch({ type: 'FETCH_RESERVATIONS_FAILURE' })
                api.errorHandler(dispatch, error.response)
            }
        )
}
export const fetchCustomers = () => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({
        type: 'FETCH_CUSTOMERS_REQUEST',
        token
    })

    return api
        .fetchCustomers(token)

        .then(
            response => {
                dispatch({ type: 'FETCH_CUSTOMERS_SUCCESS', customers: response.data })
            },
            error => {
                dispatch({ type: 'FETCH_CUSTOMERS_FAILURE' })
                api.errorHandler(dispatch, error.response)
            }
        )
}
export const rejectReservation = reservationId => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({ type: 'REJECT_RESERVATION_REQUEST', token, reservationId })

    return api.rejectReservation(token, reservationId).then(
        response => {
            dispatch({ type: 'REJECT_RESERVATION_SUCCESS', customers: response.data })
            dispatch(fetchReservations('unassigned'))
        },
        error => {
            dispatch({ type: 'REJECT_RESERVATION_FAILURE' })
            api.errorHandler(dispatch, error.response)
        }
    )
}

export const assignCustomer = (shippingAddressId, reservationId, date, po, comments) => (dispatch, getState) => {
    const token = getState().login.token
    dispatch({ type: 'ASSIGN_RESERVATION_REQUEST', token, shippingAddressId, reservationId })

    return api.assignCustomer(token, shippingAddressId, reservationId, date, po, comments).then(
        response => {
            dispatch({ type: 'ASSIGN_RESERVATION_SUCCESS', customers: response.data })
            dispatch(fetchReservations('unassigned'))
        },
        error => {
            dispatch({ type: 'ASSIGN_RESERVATION_FAILURE' })
            api.errorHandler(dispatch, error.response)
        }
    )
}
