import { getQuickGuides } from './../store/products/reducers';
import React from 'react'
import { connect } from 'react-redux'
import {
    getUserType,
    getImpersonatedBy,
    isEmserUser,
    getIsAdmin,
    getUserName,
    canViewRocketPromotion,
    canViewOutletAndNaturalStoneFromHQInOtherCenters,
    canViewSilestonePromotion
} from '../store/login/reducers'
import {
    getIsLowesCenter,
    getIsMkToolsCenter,
    getIsIkeaCenter,
    getIsEndiCenter,
    canOffer,
    getShippingMethod,
    getSelectedCenter,
    getHasMinWeight,
    getCoin,
    isLatinaVitoria,
    hasSingleSlabAvailableInSelectedCenter
} from '../store/centers/reducers'
import CheckoutV2 from '../components/checkout/CheckoutV2'
import {getLanguage, getLanguageDir, getCheckoutStep, getLocalizedUrl} from '../store/ui/reducers'
import {
    getCalculatingLinesPrice,
    getFinishStep,
    getGoalMax,
    getGoalMin,
    getIsValidVoucher,
    getKgPoundRatio,
    getLines,
    getReservationCode,
    getTransformingSkuToLots,
    isSending,
    qtySelector,
    totalCoin,
    totalSelector,
    weightSelector,
    getIsLoading,
    getReservationCutToSizeCode,
    totalSelectorMktools
} from '../store/cart/reducers'
import {
    canSendCheckoutSaleConditions,
    canSendCheckoutHealthConditions,
    getComments,
    getDeliveryDate,
    getDestination,
    getIkeaTransportArea,
    getInstallationDataAddress,
    getInstallationDataName,
    getInstallationDataPhone,
    getInstallationDataProvince,
    getInstallationDataTown,
    getInstallationDataZipCode,
    getLowesDataEmail,
    getLowesDataFirstName,
    getLowesDataLastName,
    getLowesDataTitle,
    getMinRequiredDate,
    getPo,
    getSaleConditionsCheck,
    getHealthConditionsCheck,
    getSelectedShippingAddress
} from '../store/checkout/reducers'
import { getCurrentCrossSelling, getCurrentCrossSellingCheckout } from '../store/crossSelling/reducers'
import { addCartLine, checkout, emptyCart, transformSkuToLots, isCheckoutPageScreen } from '../store/cart/actions'
import { fetchCrossSellingCheckOut } from '../store/crossSelling/actions'
import {
    canSendLowesData,
    canSendMinMaxCheckout,
    canSendNeedDestination, setComments,
    setDeliveryDate, setDestination,
    setHasSelectedCenter, setSelectedShippingAddressId,
    setStep,
    setCheckoutStepOneButtonNextDisabled
} from '../store/checkout/actions'
import { getProfileConfiguration } from '../store/profile-configuration/reducers'
import { getEmployeeId } from '../store/big-account/reducers'
import { dispatchPushURL } from '../store/ui/actions'
import { getSelectedShippingAddressForIkea, getShippingAddresses } from '../store/shipping-addresses/reducers'
import {injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";

const mapStateToProps = (state, props) => {
    let language = getLanguage(state)
    let defaultUnit = language === 'en' || language === 'en-US' || language === 'fr-CA' ? 'LB' : 'KG'
    let unit = getProfileConfiguration(state).weightUnit ? getProfileConfiguration(state).weightUnit : defaultUnit

    return {
        languageDir: getLanguageDir(state),
        isEndiCenter: getIsEndiCenter(state),
        isOffer: canOffer(state),
        total: totalSelector(state),
        totalMktools: totalSelectorMktools(state),
        coinMktools: getCoin(state),
        coin: totalCoin(state),
        lang: getLanguage(state),
        calculatingLinesPrice: getCalculatingLinesPrice(state),
        totalQty: qtySelector(state),
        hasSaleConditionsCheck: getSaleConditionsCheck(state),
        hasHealthConditionsCheck: getHealthConditionsCheck(state),
        crossSelling: getCurrentCrossSelling(state),
        crossSellingCheckout: getCurrentCrossSellingCheckout(state),
        shippingMethod: getShippingMethod(state),
        lines: getLines(state),
        userType: getUserType(state),
        impersonatedBy: getImpersonatedBy(state),
        isLowesCenter: getIsLowesCenter(state),
        isMktoolsCenter: getIsMkToolsCenter(state),
        isIkeaCenter: getIsIkeaCenter(state),
        isValidVoucher: getIsValidVoucher(state),
        isSending: isSending(state),
        canSendCheckoutSaleConditions: canSendCheckoutSaleConditions(state),
        canSendCheckoutHealthConditions: canSendCheckoutHealthConditions(state),
        transformingSkuToLots: getTransformingSkuToLots(state),
        isEmserUser: isEmserUser(state),
        po: getPo(state),
        finishStep: getFinishStep(state),
        reservationCode: getReservationCode(state),
        reservationCutToSizeCode: getReservationCutToSizeCode(state),
        hasDestination: getShippingMethod(state) === 'CONTAINER',
        destination: getDestination(state),
        selectedShippingAddress: getSelectedShippingAddress(state),
        deliveryDate: getDeliveryDate(state),
        minDeliveryDate: getMinRequiredDate(state),
        centerSelected: getSelectedCenter(state),
        current: unit === 'KG' ? weightSelector(state) : Math.floor(weightSelector(state) * getKgPoundRatio()),
        goalMin: getGoalMin(state, unit),
        goalMax: getGoalMax(state, unit),
        hasMinWeight: getHasMinWeight(state),
        isAdmin: getIsAdmin(state),
        employeeId: getEmployeeId(state),
        ikeaTransportArea: getIkeaTransportArea(state),
        userName: getUserName(state),
        installationDataName: getInstallationDataName(state),
        installationDataAddress: getInstallationDataAddress(state),
        installationDataTown: getInstallationDataTown(state),
        installationDataProvince: getInstallationDataProvince(state),
        installationDataZipCode: getInstallationDataZipCode(state),
        installationDataPhone: getInstallationDataPhone(state),
        comments: getComments(state),
        shippingAddresses: getShippingAddresses(state),
        ikeaSelectedShippingAddress: getSelectedShippingAddressForIkea(state),
        lowesDataFirstName: getLowesDataFirstName(state),
        lowesDataLastName: getLowesDataLastName(state),
        lowesDataEmail: getLowesDataEmail(state),
        lowesDataTitle: getLowesDataTitle(state),
        currentStep: getCheckoutStep(state),
        isLoading: getIsLoading(state),
        canViewRocketPromotion: canViewRocketPromotion(state),
        extendedPath: (filter) => getLocalizedUrl(state, props.intl, 'ROUTE_CHECKOUT') + '/' + filter,
        history: props.history,
        quickGuides:getQuickGuides(state),
        canViewOutletAndNaturalStoneFromHQInOtherCenters: canViewOutletAndNaturalStoneFromHQInOtherCenters(state),
        isLatinaVitoria: isLatinaVitoria(state),
        canViewSilestonePromotion: canViewSilestonePromotion(state),
        hasSingleSlabAvailableInSelectedCenter: hasSingleSlabAvailableInSelectedCenter(state),
        isChangingCartQty: state.cart.isChangingCartQty
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addCartLine: (product, qty, extra, callbackCenter, dektonGripRequested) => {
            dispatch(addCartLine(product, qty, extra, callbackCenter, dektonGripRequested))
        },
        fetchCrossSelling: productIds => {
            return dispatch(fetchCrossSellingCheckOut(productIds))
        },
        canSendMinMaxCheckout: () => dispatch(canSendMinMaxCheckout()),
        canSendNeedDestination: () => dispatch(canSendNeedDestination()),
        submitCheckoutFormCustomer: () => dispatch(checkout(true)),
        submitCheckoutFormMktools: () => dispatch(checkout()),
        submitCheckoutFormIkea: () => dispatch(checkout()),
        submitCheckout: () => dispatch(checkout()),
        transformSkuToLots: () => {
            return dispatch(transformSkuToLots())
        },
        setDeliveryDate: value => dispatch(setDeliveryDate(value)),
        setHasSelectedCenter: value => dispatch(setHasSelectedCenter(value)),
        setSelectedShippingAddressId: value => dispatch(setSelectedShippingAddressId(value)),
        setDestination: value => dispatch(setDestination(value)),
        setComments: value => dispatch(setComments(value)),
        setStep: value => dispatch(setStep(value)),
        goCatalog: intl => {
            dispatch(dispatchPushURL(intl.formatMessage({ id: 'ROUTE_CATALOG' }).replace(':outlet?', '')))
            dispatch({ type: 'REMEMBER_CENTER' })
        },
        canSendLowesData: () => dispatch(canSendLowesData()),
        emptyCart: () => {
            dispatch({ type: 'EMPTY_CART_BY_USER' })
            dispatch(emptyCart())
        },
        redirect: url => {
            dispatch(dispatchPushURL(url))
        },
        setIsCheckoutPage: (isCheckoutPage) => dispatch(isCheckoutPageScreen(isCheckoutPage)),
        setCheckoutStepOneButtonNextDisabled: (checkoutStepOneButtonNextDisabled) => dispatch(setCheckoutStepOneButtonNextDisabled(checkoutStepOneButtonNextDisabled))
    }
}

export default withRouter(injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutV2)))
