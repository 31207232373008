import React from 'react'

import Kpis from '../components/kpis/Kpis'

class KpisScreen extends React.Component {
    render() {
        return <Kpis />
    }
}

export default KpisScreen
