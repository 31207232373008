import moment from 'moment'
import { isMobile } from 'react-device-detect'

const initState = {
    currentDeliveryId: undefined,
    deliveryDetails: [],
    summary: undefined,
    openOrders: null,
    filtersOpen: !isMobile,
    filters: {
        startDate: moment().startOf('year'),
        endDate: moment(),
        centers: [],
        customers: [],
        topColors: []
    }
}

const kpis = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_DELIVERY_DETAIL_REQUEST':
            return Object.assign({}, state, { currentDeliveryId: action.deliveryId })
        case 'CLOSE_DELIVERY_DETAIL':
            return Object.assign({}, state, { currentDeliveryId: undefined })
        case 'FETCH_DELIVERY_DETAIL_SUCCESS':
            return Object.assign({}, state, { deliveryDetails: action.deliveryDetails })
        case 'FETCH_KPIS_REQUEST':
        case 'FETCH_KPIS_OPEN_ORDERS_REQUEST':
        case 'FETCH_KPIS_BACK_ORDERS_REQUEST':
            return Object.assign({}, state, { isLoading: true })
        case 'FETCH_KPIS_SUCCESS':
            return Object.assign({}, state, { summary: action.summary, isLoading: false })
        case 'FETCH_KPIS_OPEN_ORDERS_SUCCESS':
            return Object.assign({}, state, { openOrders: action.response, isLoading: false })
        case 'FETCH_KPIS_BACK_ORDERS_SUCCESS':
            return Object.assign({}, state, { backOrders: action.response, isLoading: false })
        case 'SET_KPIS_FILTERS':
            return Object.assign({}, state, { filters: action.filters })
        case 'TOGGLE_KPIS_FILTERS':
            return Object.assign({}, state, { filtersOpen: action.showFilters })

        default:
            return state
    }
}

export const currentDeliveryId = state => {
    return state.kpis.currentDeliveryId
}
export const currentDeliveryDetailLines = state => {
    return state.kpis.deliveryDetails.filter(deliveryDetail => {
        return deliveryDetail.deliveryId === state.kpis.currentDeliveryId
    })
}
export const getSummary = state => {
    return state.kpis.summary
}
export const getBackOrders = state => {
    return state.kpis.backOrders
}
export const getOpenOrders = state => {
    return state.kpis.openOrders
}

export const getIsLoading = state => {
    return state.kpis.isLoading
}
export const getFilters = state => {
    return state.kpis.filters
}
export const getFiltersOpen = state => {
    return state.kpis.filtersOpen
}
export const getFiltersToApi = state => {
    const filters = getFilters(state)
    const data = {
        startDate: filters.startDate,
        endDate: filters.endDate,
        centerIds: filters.centers.map(center => {
            return center.value
        }),
        customerIds: filters.customers.map(customer => {
            return customer.value
        }),
        colorIds: filters.topColors
    }
    return data
}
export default kpis
