import { connect } from 'react-redux'
import * as ui from '../../store/ui/actions'
import { showingAdd2CartSuccessModal } from '../../store/ui/reducers'
import Add2CartSuccess from './Add2CartSuccess'

const mapStateToProps = state => {
    return {
        visible: showingAdd2CartSuccessModal(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        close: () => {
            dispatch(ui.closeAdd2CartSuccessModal())
        }
    }
}

const Add2CartSuccessContainer = connect(mapStateToProps, mapDispatchToProps)(Add2CartSuccess)

export default Add2CartSuccessContainer
