import * as api from '../api'
import { getToken } from '../login/reducers'

export const fetchTemplatesLowes = () => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_TEMPLATES_LOWES_REQUEST'
    })
    return api
        .fetchTemplatesLowes(getToken(getState()))

        .then(
            response => {
                dispatch({ type: 'FETCH_TEMPLATES_LOWES_SUCCESS', response: response.data })
            },
            error => {
                dispatch({ type: 'FETCH_TEMPLATES_LOWES_FAILURE' })
                api.errorHandler(dispatch, error.response)
            }
        )
}
export const sendCheckoutLowes = cart => (dispatch, getState) => {
    dispatch({ type: 'SEND_CHECKOUT_LOWES_REQUEST' })
    return api.sendCheckoutLowes(cart).then(
        response => {
            dispatch({ type: 'SEND_CHECKOUT_LOWES_SUCCESS', response: response.data })
        },
        error => {
            dispatch({ type: 'SEND_CHECKOUT_LOWES_FAILURE' })
            api.errorHandler(dispatch, error.response)
        }
    )
}
export const fetchUsersShopperLowes = () => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_USERS_SHOPPER_LOWES_REQUEST'
    })
    return api
        .fetchUsersShopperLowes(getToken(getState()))

        .then(
            response => {
                dispatch({ type: 'FETCH_USERS_SHOPPER_LOWES_SUCCESS', response: response.data })
            },
            error => {
                dispatch({ type: 'FETCH_USERS_SHOPPER_LOWES_FAILURE' })
                api.errorHandler(dispatch, error.response)
            }
        )
}
export const updateStoreLowes = (storeId, templates) => (dispatch, getState) => {
    dispatch({
        type: 'UPDATE_STORE_LOWES_REQUEST'
    })
    return api
        .updateStore(getToken(getState()), storeId, templates)

        .then(
            response => {
                dispatch({ type: 'UPDATE_STORE_LOWES_SUCCESS', response: response.data })
            },
            error => {
                dispatch({ type: 'UPDATE_SOTRE_LOWES_FAILURE' })
                api.errorHandler(dispatch, error.response)
            }
        )
}
export const fetchProductsLowes = () => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_PRODUCTS_LOWES_REQUEST'
    })
    return api
        .fetchProductsLowes(getToken(getState()))

        .then(
            response => {
                dispatch({ type: 'FETCH_PRODUCTS_LOWES_SUCCESS', response: response.data })
            },
            error => {
                dispatch({ type: 'FETCH_PRODUCTS_LOWES_FAILURE' })
                api.errorHandler(dispatch, error.response)
            }
        )
}
export const fetchBrochuresLowes = () => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_BROCHURES_LOWES_REQUEST'
    })
    return api
        .fetchBrochuresLowes()

        .then(
            response => {
                dispatch({ type: 'FETCH_BROCHURES_LOWES_SUCCESS', response: response.data })
            },
            error => {
                dispatch({ type: 'FETCH_BROCHURES_LOWES_FAILURE' })
                api.errorHandler(dispatch, error.response)
            }
        )
}
export const createNewTemplate = template => (dispatch, getState) => {
    dispatch({ type: 'ADD_NEW_TEMPLATE', response: template })
    return Promise.resolve()
}
export const saveTemplate = template => (dispatch, getState) => {
    dispatch({
        type: 'SAVE_TEMPLATE_REQUEST'
    })
    return api
        .saveTemplateLowes(getToken(getState()), template)

        .then(
            response => {
                dispatch({ type: 'SAVE_TEMPLATE_SUCCESS', response: response.data })
                dispatch(fetchTemplatesLowes())
            },
            error => {
                dispatch({ type: 'SAVE_TEMPLATE_FAILURE' })
                api.errorHandler(dispatch, error.response)
            }
        )
}
export const updateTemplate = template => (dispatch, getState) => {
    dispatch({
        type: 'UPDATE_TEMPLATE_REQUEST'
    })
    return api
        .updateTemplateLowes(getToken(getState()), template)

        .then(
            response => {
                dispatch({ type: 'UPDATE_TEMPLATE_SUCCESS', response: response.data })
                dispatch(fetchTemplatesLowes())
            },
            error => {
                dispatch({ type: 'UPDATE_TEMPLATE_FAILURE' })
                api.errorHandler(dispatch, error.response)
            }
        )
}
export const enableTemplate = templateId => (dispatch, getState) => {
    dispatch({
        type: 'ENABLE_TEMPLATE_REQUEST'
    })
    return api
        .enableTemplateLowes(getToken(getState()), templateId)

        .then(
            response => {
                dispatch({ type: 'ENABLE_TEMPLATE_SUCCESS', response: response.data })
                dispatch(fetchTemplatesLowes())
            },
            error => {
                dispatch({ type: 'ENABLE_TEMPLATE_FAILURE' })
                api.errorHandler(dispatch, error.response)
            }
        )
}
export const disableTemplate = templateId => (dispatch, getState) => {
    dispatch({
        type: 'DISABLE_TEMPLATE_REQUEST'
    })
    return api
        .disableTemplateLowes(getToken(getState()), templateId)

        .then(
            response => {
                dispatch({ type: 'DISABLE_TEMPLATE_SUCCESS', response: response.data })
                dispatch(fetchTemplatesLowes())
            },
            error => {
                dispatch({ type: 'DISABLE_TEMPLATE_FAILURE' })
                api.errorHandler(dispatch, error.response)
            }
        )
}
export const deleteTemplate = templateId => (dispatch, getState) => {
    dispatch({
        type: 'DELETE_TEMPLATE_REQUEST'
    })
    return api
        .deleteTemplateLowes(getToken(getState()), templateId)

        .then(
            response => {
                dispatch({ type: 'DELETE_TEMPLATE_SUCCESS', response: response.data })
                dispatch(fetchTemplatesLowes())
            },
            error => {
                dispatch({ type: 'DELETE_TEMPLATE_FAILURE' })
                api.errorHandler(dispatch, error.response)
            }
        )
}
export const editTemplateName = (templateId, name) => (dispatch, getState) => {
    dispatch({ type: 'CHANGE_TEMPLATE_NAME_REQUEST' })
    return api.editTemplateName(getToken(getState()), templateId, name).then(
        response => {
            dispatch({ type: 'CHANGE_TEMPLATE_NAME_SUCCESS', response: { id: templateId, name: name } })
        },
        error => {
            dispatch({ type: 'CHANGE_TEMPLATE_NAME_FAILURE' })
        }
    )
}
export const sendFormRegister = (email, user, pass) => (dispatch, getState) => {
    dispatch({ type: 'SEND_FORM_REGISTER_REQUEST', email, user, pass })
    return api.sendFormRegister(email, user, pass).then(
        response => {
            dispatch({ type: 'SEND_FORM_REGISTER_SUCCESS', response })
        },
        error => {
            dispatch({ type: 'SEND_FORM_REGISTER_FAILURE', error })
        }
    )
}
export const sendFormNewRegister = (email, name, lastName, storeNumber, pass) => (dispatch, getState) => {
    dispatch({ type: 'SEND_FORM_REGISTER_REQUEST', email, name, lastName, storeNumber, pass })
    return api.sendFormNewRegister(email, name, lastName, storeNumber, pass).then(
        response => {
            dispatch({ type: 'SEND_FORM_REGISTER_SUCCESS', response })
        },
        error => {
            dispatch({ type: 'SEND_FORM_REGISTER_FAILURE', error })
        }
    )
}
