import { connect } from 'react-redux'
import Transfers from './Transfers'
import { getOrdersLoading, getTransferOrders } from '../../store/orders/reducers'
import { injectIntl } from 'react-intl'
import { fetchOrders } from '../../store/orders/actions'
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state, { match }) => {
    return {
        transferOrders: getTransferOrders(state),

        isLoading: getOrdersLoading(state)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchOrders: (type, startDate, endDate) => {
            dispatch(fetchOrders(type, startDate, endDate))
        }
    }
}

const TransfersContainer = withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Transfers)))

export default TransfersContainer
