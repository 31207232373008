import { connect } from 'react-redux'
import { getTemplates, getIdConfirmation, getUsersShopper, getProductsLowes } from '../../store/lowes/reducers'
import { fetchTemplatesLowes, sendCheckoutLowes, fetchUsersShopperLowes, fetchProductsLowes, createNewTemplate, saveTemplate } from '../../store/lowes/actions'
import { getIsLoading, getBrochuresLowes } from '../../store/lowes/reducers'
import { deleteTemplate, updateTemplate, enableTemplate, disableTemplate, editTemplateName, fetchBrochuresLowes } from '../../store/lowes/actions'
import CatalogLowesAdmin from './CatalogLowesAdmin'

const mapStateToProps = state => {
    return {
        templates: getTemplates(state),
        idConfirmation: getIdConfirmation(state),
        products: getProductsLowes(state),
        brochures: getBrochuresLowes(state),
        usersShopper: getUsersShopper(state),
        isLoading: getIsLoading(state)
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchTemplatesLowes: () => {
            dispatch(fetchTemplatesLowes())
        },
        fetchProductsLowes: () => {
            dispatch(fetchProductsLowes())
        },
        fetchBrochuresLowes: () => {
            dispatch(fetchBrochuresLowes())
        },
        fetchUsersShopperLowes: () => {
            dispatch(fetchUsersShopperLowes())
        },
        sendCheckoutLowes: cart => {
            return dispatch(sendCheckoutLowes(cart))
        },
        createNewTemplate: template => {
            return dispatch(createNewTemplate(template))
        },
        saveTemplate: template => {
            return dispatch(saveTemplate(template))
        },
        deleteTemplate: templateId => {
            dispatch(deleteTemplate(templateId))
        },
        updateTemplate: template => {
            dispatch(updateTemplate(template))
        },
        enableTemplate: templateId => {
            dispatch(enableTemplate(templateId))
        },
        disableTemplate: templateId => {
            dispatch(disableTemplate(templateId))
        },
        editTemplateName: (templateId, name) => {
            return dispatch(editTemplateName(templateId, name))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CatalogLowesAdmin)
