import React from 'react'

import { injectIntl } from 'react-intl'

class OrderTransfersStatus extends React.Component {
    render() {
        const { status } = this.props
        let avion = ['A', 'B', 'C'].includes(status)
        let lupa = ['B', 'C'].includes(status)
        let tick = ['C'].includes(status)

        return (
            <div>
                <div className="tracking-progress-bar">
                    {avion && (
                        <div>
                            <div
                                className={`tracking-progress-bar__item tracking-progress-bar__item--first
                                                            ${avion ? 'tracking-progress-bar__item--active' : null}`}
                            >
                                <i className="fal fa-paper-plane" />
                            </div>

                            <span className={`tracking-progress-bar__item__bar ${lupa ? 'tracking-progress-bar__item__bar--active' : null}`} />
                            <div
                                className={`tracking-progress-bar__item tracking-progress-bar__item--first
                            ${lupa ? 'tracking-progress-bar__item--active' : null}`}
                            >
                                <i className="fal fa-search" />
                            </div>

                            <span className={`tracking-progress-bar__item__bar ${tick ? 'tracking-progress-bar__item__bar--active' : null}`} />
                            <div
                                className={`tracking-progress-bar__item tracking-progress-bar__item--first
                            ${tick ? 'tracking-progress-bar__item--active' : null}`}
                            >
                                <i className="fal fa-check" />
                            </div>
                        </div>
                    )}
                </div>
                {/* <span>
                    {original.statusText &&
                        this.props.intl.formatMessage({
                            id: original.statusText
                                .replace(/ /g, '_')
                                .replace(/\//g, '_')
                                .toLowerCase()
                        })}
                </span> */}
            </div>
        )
    }
}

export default injectIntl(OrderTransfersStatus)
