import React from 'react'
import Translation from './Translation'

export const typeMap = {
    TABLA: <Translation id="tables" defaultMessage="Slabs" />,
    PPCC: <Translation id="professional_products" defaultMessage="Professional Products" />,
    FREGADERO: <Translation id="sinks" defaultMessage="Sinks" />,
    MK: <Translation id="mktool" defaultMessage="Marketing Tools" />,
    SH: <Translation id="showroom" defaultMessage="Showroom" />,
    WH: <Translation id="warehouse" defaultMessage="Warehouse" />,
    OPERATIONS: <Translation id="OPERATIONS" defaultMessage="Operations" />,
    SERVICE: <Translation id="SERVICE" defaultMessage="Services" />,
    ENCIMERA: <Translation id="WORKTOPS" defaultMessage="Worktops" />,
    BAÑOS: <Translation id="baths_and_wall_cladding" defaultMessage="Bathrooms and Wall Cladding" />,
    '01': <Translation id="SAMPLES" defaultMessage="Samples" />,
    '02': <Translation id="OTHERS" defaultMessage="Others" />,
    '03': <Translation id="MERCHANDISING" defaultMessage="Merchandising" />,
    '04': <Translation id="LITERATURE" defaultMessage="Literature" />,
    '05': <Translation id="TAGS" defaultMessage="Tags" />,
    '06': <Translation id="ANCHORING" defaultMessage="anchoring" />,
    '07': <Translation id="BOXES" defaultMessage="Boxes" />,
    '08': <Translation id="DISPLAYS" defaultMessage="Displays" />,
    '09': <Translation id="BOOKS" defaultMessage="Books" />,
    '10': <Translation id="CABINETS" defaultMessage="Cabinets" />,
    '11': <Translation id="SIGNAGE" defaultMessage="Signage" />,
    '12': <Translation id="TEST_KITS" defaultMessage="Test kits" />,
    '13': <Translation id="WORKWEAR" defaultMessage="Workwear" />,
    '14': <Translation id="EPP" defaultMessage="EPP" />,
    '15': <Translation id="WAREHOUSE_SUPPLIES" defaultMessage="warehouse supplies" />
}